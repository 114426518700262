































import { Component, Mixins } from 'vue-property-decorator';
import NotificationLevelIcon from '@/components/notification/NotificationLevelIcon.vue';
import Avatar from '@/components/Avatar.vue';
import FormatsDate from '@/components/mixins/FormatsDate';
import RunActions from '@/components/run/RunActions.vue';
import NotifiedRolesIndicator from '@/components/NotifiedRolesIndicator.vue';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import NotificationActions from '@/components/notification/NotificationActions.vue';
import UsesNotificationMessage from '@/components/mixins/UsesNotificationMessage';
import TranslatesVehiclePhase from '@/components/mixins/TranslatesVehiclePhase';
import NotificationQuestionnaireButton from '@/components/notification/NotificationQuestionnaireButton.vue';

@Component({
    components: { NotificationQuestionnaireButton, NotificationActions, NotifiedRolesIndicator, RunActions, Avatar, NotificationLevelIcon },
})
export default class RunsTableNotificationRow extends Mixins(
    UsesNotificationDetails,
    FormatsDate,
    UsesNotificationMessage,
    TranslatesVehiclePhase,
) {

}

