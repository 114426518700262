import { QuestionnaireState } from './questionnaireState';
import {
    NotificationObstacleDetectionLabelType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';
import { Notification } from '@/core/interfaces/notification';

const mutations = {
    reset: (state: QuestionnaireState) => {
        state.notification = null;
        state.labels = [];
        state.answers = [];
        state.robotData = [];
        state.done = false;
        state.currentId = state.items[0].getId();
    },

    setCurrentId: (state: QuestionnaireState, payload: string) => {
        state.currentId = payload;
    },

    setNotification: (state: QuestionnaireState, payload: Notification) => {
        state.notification = payload;
    },

    setLabels: (state: QuestionnaireState, payload: NotificationObstacleDetectionLabelType[]) => {
        state.labels = payload;
    },

    setRobotDataRecord: (state: QuestionnaireState, payload: Record<string, any>) => {
        state.robotData = Object.entries(payload).map(([id, value]) => ({ id, value }));
    },

    setAnswers: (state: QuestionnaireState, payload: {value: boolean | string[] | string; id: string}[]) => {
        state.answers = payload;
    },

    setOriginalAnswersRecord: (state: QuestionnaireState, payload: Record<string, boolean | string[] | string>) => {
        state.originalAnswers = Object.entries(payload).map(([id, value]) => ({ id, value }));
    },

    setAnswer: (state: QuestionnaireState, payload: { value: boolean | string[] | string; id: string }) => {
        if (state.answers.map(answer => answer.id).includes(payload.id)) {
            state.answers = state.answers.filter(answer => answer.id !== payload.id);
        }

        state.answers.push(payload);

        if (state.originalAnswers.map(answer => answer.id).includes(payload.id)) {
            state.originalAnswers = state.originalAnswers.filter(answer => answer.id !== payload.id);
        }

        state.originalAnswers.push(payload);
    },

    addLabel: (state: QuestionnaireState, payload: NotificationObstacleDetectionLabelType) => {
        state.labels.push(payload);
    },

    setDone: (state: QuestionnaireState, payload: boolean) => {
        state.done = payload;
    },
};

export default mutations;
