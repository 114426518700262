import { ActionTree } from 'vuex';
import { QuestionnaireState } from './questionnaireState';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { Notification } from '@/core/interfaces/notification';
import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import NotificationObstacleDetectionLabel
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionLabel';
import NotificationObstacleDetectionBooleanQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionBooleanQuestion';

const isLabel = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionLabel =>
    item.getType() === 'LABEL';

const isBoolean = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionBooleanQuestion =>
    item.getType() === 'BOOLEAN';

const isMultipleChoice = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionBooleanQuestion =>
    item.getType() === 'MULTIPLE_CHOICE';

const actions: ActionTree<QuestionnaireState, RootState> = {
    save: async({ state }) => {
        const notificationId = state.notification?.id;

        if (!notificationId) {
            throw new Error('No notification id');
        }

        await axios.put(`notifications/${notificationId}/obstacle_detection_questionnaire`, {
            answers: state.answers.reduce((acc: Record<string, string[] | boolean | string>, answer: {id: string; value: boolean | string[] | string}) => {
                acc[answer.id] = answer.value;

                return acc;
            }, {}),
            labels: state.labels,
        });

        await axios.post(`notifications/${notificationId}/questionnaire_completed`);
    },

    fetch: async({ commit }, notification: Notification) => {
        commit('reset');
        commit('setNotification', notification);

        try {
            const response = (await axios.get(`notifications/${notification.id}/obstacle_detection_questionnaire`)).data;

            commit('setLabels', response.labels || []);
            commit('setOriginalAnswersRecord', response.answers || {});
            commit('setRobotDataRecord', response.robot_data || {});
        } catch (error) {
            // noop
        }
    },

    removeLabelsAndQuestionsAfterId: ({ commit, state, getters }, id: string) => {
        const items = getters.itemsFlattened as NotificationObstacleDetectionQuestionnaireItemInterface[];
        const idx = items.findIndex(item => item.getId() === id);

        if (idx === -1) {
            commit('setLabels', []);

            return;
        }
        const remainingItems = items.slice(idx + 1);

        commit('setLabels', state.labels.filter(label =>
            !remainingItems.some(item => isLabel(item) && item.getLabel() === label)));

        commit('setAnswers', state.answers.filter(answer =>
            !remainingItems.some(item => item.getId() === answer.id)));
    },
};

export default actions;
