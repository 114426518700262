import { Module } from 'vuex';
import { RootState } from '@/store/rootState';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';
import { QuestionnaireState } from './questionnaireState';

const questionnaireState: Module<QuestionnaireState, RootState> = {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};

export default questionnaireState;
