import {
    NotificationObstacleDetectionQuestionLabelType, NotificationObstacleQuestionLabels,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionQuestion {
    constructor(
        private readonly id: string,
        private readonly labelType: NotificationObstacleDetectionQuestionLabelType,
    ) {
    }

    public getId(): string {
        return this.id;
    }

    public getLabelType(): NotificationObstacleDetectionQuestionLabelType {
        return this.labelType;
    }

    public getLabel(): string {
        return NotificationObstacleQuestionLabels[this.labelType];
    }
}
