import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import {
    NotificationObstacleDetectionLabelType, NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionLabel implements NotificationObstacleDetectionQuestionnaireItemInterface {
    constructor(
        private readonly id: string,
        private readonly labelType: NotificationObstacleDetectionLabelType,
        private readonly exit = false,
    ) {

    }

    public getId(): string {
        return this.id;
    }

    public getType(): NotificationObstacleDetectionQuestionnaireItemType {
        return 'LABEL';
    }

    public getLabel(): string {
        return this.labelType;
    }

    public isExit(): boolean {
        return this.exit;
    }
}
