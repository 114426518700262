













































































import Component from 'vue-class-component';
import Vue from 'vue';
import {
    CHOICES_ENVIRONMENTAL_CONDITIONS,
    CHOICES_GROUND_TYPE,
    CHOICES_OBSTACLE_TYPE,
    NotificationObstacleDetectionQuestionLabelType,
    NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class NotificationObstacleDetectionQuestionnaireQuestion extends Vue {
    @Prop({ required: true })
    label!: string;

    @Prop({ required: true })
    questionType!: NotificationObstacleDetectionQuestionnaireItemType;

    @Prop({ required: true })
    labelType!: NotificationObstacleDetectionQuestionLabelType;

    @Prop({ default: () => [] })
    answer!: boolean | string[] | string;

    @Prop({ type: Boolean })
    hasPrevious!: boolean;

    selectedOptions: string[] = [];

    otherOptions: {text: string; enabled: boolean}[] = [
        { text: '', enabled: false },
    ];

    mounted(): void {
        if (this.questionType === 'MULTIPLE_CHOICE') {
            if (Array.isArray(this.answer)) {
                this.selectedOptions = this.answer.filter((answer) => !answer.startsWith('Other:'));

                this.otherOptions = this.answer
                    .filter((answer) => answer.startsWith('Other:'))
                    .map((answer) => ({ text: answer.replace('Other: ', ''), enabled: true }));
            } else {
                const answer = String(this.answer);

                if (answer.startsWith('Other: ')) {
                    this.otherOptions = [{ text: answer.replace('Other: ', ''), enabled: true }];
                } else {
                    this.selectedOptions = [answer];
                }
            }

            if (!this.otherOptions.length) {
                this.otherOptions = [{ text: '', enabled: false }];
            }
        }
    }

    get disabled(): boolean {
        return !this.selectedOptions.length && !this.otherOptions.some((option) => option.enabled);
    }

    get multipleAnswersAllowed(): boolean {
        return this.questionType === 'MULTIPLE_CHOICE' && this.labelType !== 'OBSTACLE_TYPE_PRESENT';
    }

    get selectedOption(): string {
        return this.selectedOptions[0] || '';
    }

    set selectedOption(value: string) {
        this.$set(this.selectedOptions, 0, value);
        this.otherOptions[0].enabled = false;
    }

    get currentItemOptions(): string[] {
        if (this.questionType !== 'MULTIPLE_CHOICE') {
            return [];
        }

        switch (this.labelType) {
            case 'ENVIRONMENTAL_CONDITIONS':
                return CHOICES_ENVIRONMENTAL_CONDITIONS;
            case 'GROUND_TYPE':
                return CHOICES_GROUND_TYPE;
            case 'OBSTACLE_TYPE_PRESENT':
                return CHOICES_OBSTACLE_TYPE;
            default:
                throw new Error(`Unknown label type: ${this.labelType}`);
        }
    }

    submit(): void {
        if (this.questionType === 'MULTIPLE_CHOICE') {
            this.answerMultipleChoice();
        } else {
            throw new Error('Unsupported question type for submitting form');
        }
    }

    answerMultipleChoice(): void {
        if (!this.multipleAnswersAllowed) {
            this.$emit('answer', this.selectedOptions[0] || `Other: ${this.otherOptions[0].text}`);

            return;
        }

        this.$emit('answer', [
            ...this.selectedOptions,
            ...this.otherOptions
                .filter(option => option.enabled)
                .map((option) => `Other: ${option.text}`),
        ]);
    }

    updateOtherOptions(): void {
        if (!this.multipleAnswersAllowed) {
            return;
        }

        this.otherOptions = this.otherOptions.filter((option) => option.enabled);

        if (!this.otherOptions.length || this.otherOptions[this.otherOptions.length - 1].enabled) {
            this.otherOptions.push({ text: '', enabled: false });
        }
    }

    updateOtherOptionText(index: number, text: string): void {
        this.$set(this.otherOptions, index, { text, enabled: text ? true : this.otherOptions[index].enabled });

        this.updateOtherOptions();
    }

    updateOtherOptionEnabled(index: number, enabled: boolean): void {
        if (!this.multipleAnswersAllowed && enabled) {
            this.selectedOptions = [];
        }

        this.$set(this.otherOptions, index, { text: this.otherOptions[index].text, enabled });

        this.updateOtherOptions();
    }
}
