import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { RootState } from '@/store/rootState';
import vehicleDashboard from '@/store/modules/vehicleDashboard';
import settings from '@/store/modules/settings';
import runs from '@/store/modules/runs';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';
import state from '@/store/state';
import relay from '@/store/modules/relay';
import tickets from '@/store/modules/tickets';
import dashboard from '@/store/modules/dashboard';
import knowledgeBase from '@/store/modules/knowledgeBase';
import questionnaire from '@/store/modules/questionnaire';

Vue.use(Vuex);

const modules: ModuleTree<RootState> = {
    vehicleDashboard,
    settings,
    runs,
    relay,
    tickets,
    dashboard,
    knowledgeBase,
    questionnaire,
};

const store = new Vuex.Store({
    modules,
    state,
    mutations,
    actions,
    getters,
    plugins: [createPersistedState({
        paths: [
            'runs.runs',
            'runs.runsList',
            'roles',
            'rolesList',
            'notificationTypes',
            'notificationTypesList',
            'currentUser',
            'farms',
            'farmsList',
            'users',
            'usersList',
            'vehicles',
            'vehiclesList',
            'vehiclePhases',
            'vehiclePhasesList',
            'vehicleTypes',
            'vehicleTypesList',
            'settings.visibleTimetableRoles',
            'locales',
            'runViewPreference',
            'tickets.tickets',
            'tickets.categories',
            'tickets.seenTickets',
            'tickets.seenMessages',
            'visibleVehicleTypeIds',
            'visibleNotificationLevels',
            'groupRuns',
        ],
    })],
});

export default store;
