export type NotificationObstacleDetectionQuestionnaireItemType =
    | 'BOOLEAN'
    | 'MULTIPLE_CHOICE'
    | 'SWITCH'
    | 'LABEL'
    | 'DERIVED';

export type NotificationObstacleDetectionQuestionLabelType =
    | 'IS_OBSTACLE_PRESENT'
    | 'DID_OBSTACLE_COLLIDE_WITH_EXOS'
    | 'ENVIRONMENTAL_CONDITIONS'
    | 'GROUND_TYPE'
    | 'OBSTACLE_TYPE_PRESENT'
    | 'DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER';

export type NotificationObstacleDetectionDerivedQuestionType =
    | 'DID_THE_EXOS_DETECT_OBSTACLE'
    | 'IS_BUMPER_TRIGGERED'
    | 'CORRECT_OBSTACLE_CLASSIFICATION'
    | 'DID_EXOS_MAKE_PROTECTIVE_STOP'
    | 'MUST_STOP_FOR_OBSTACLE';

export const NotificationObstacleQuestionLabels: Record<NotificationObstacleDetectionQuestionLabelType, string> = {
    IS_OBSTACLE_PRESENT: 'Is er een obstakel aanwezig in het rijvlak van de EXOS?',
    DID_OBSTACLE_COLLIDE_WITH_EXOS: 'Is het obstakel in contact gekomen met de EXOS?',
    ENVIRONMENTAL_CONDITIONS: 'Wat zijn de omgeving omstandigheden?',
    GROUND_TYPE: 'Wat is de ondergrond?',
    OBSTACLE_TYPE_PRESENT: 'Welke obstakel is er aanwezig in het rijvlak van de EXOS?',
    DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: 'Heeft het obstakel contact geïnitieerd met de bumper?',
};

export const CHOICES_ENVIRONMENTAL_CONDITIONS = [
    'Dag',
    'Nacht',
    'Verblind door zon',
    'Verblind door licht',
    'Regen',
    'Hagel',
    'Sneeuw',
    'Stof',
    'Mist',
    'Druppels op de lens',
    'Condens op de lens',
    'Vieze lens',
    'Schaduw',
];

export const CHOICES_GROUND_TYPE = [
    'Weg (asfalt)',
    'Weg (bestrating)',
    'Weg (beton)',
    'Sloot (water)',
    'Gras (gras)',
    'Sneeuw (sneeuw)',
    'Onverhard (onverhard)',
    'Onverhard (grind)',
];

export const CHOICES_OBSTACLE_TYPE = [
    'Persoon',
    'Koe',
    'Dier',
    'Voertuig',
    'Materiaal',
    'Constructie',
    'Hek',
    'Voerhek',
    'Rode lijn',
    'Lader',
    'Weg',
    'Onverhard',
    'Sloot',
    'Vegetatie',
    'Gras',
    'Voer',
    'Bakstenen muur',
    'Lely voertuig',
    'Struikgewas',
    'Onduidelijk',
    'Vogel',
    'Sneeuw',
    'Exos',
];

export const SHOULD_STOP_FOR_OBSTACLE_TYPES = [
    'Persoon',
    'Koe',
    'Dier',
    'Voertuig',
    'Materiaal',
    'Constructie',
    'Hek',
    'Voerhek',
    'Voer',
    'Bakstenen muur',
    'Lely voertuig',
    'Struikgewas',
];

export type NotificationObstacleDetectionLabelType =
    | 'BUMPER_HIT_BEYOND_CONTROL_OF_EXOS'
    | 'BUMPER_HIT_DUE_TO_FAILED_CLASSIFICATION'
    | 'BUMPER_HIT_DUE_TO_FAILED_ODS'
    | 'BUMPER_HIT_DUE_TO_FAILED_PROTECTIVE_STOP'
    | 'BUMPER_HIT_DUE_TO_OBSTACLE_OUT_OF_SIGHT'
    | 'CORRECT_CLASSIFICATION'
    | 'CRASH_EVENT_BEYOND_CONTROL_OF_EXOS'
    | 'CRASH_EVENT_DUE_TO_FAILED_CLASSIFICATION'
    | 'CRASH_EVENT_DUE_TO_FAILED_OBSTACLE_DETECTION'
    | 'CRASH_EVENT_DUE_TO_FAILED_PROTECTIVE_STOP'
    | 'CRASH_EVENT_DUE_TO_OBSTACLE_OUT_OF_SIGHT'
    | 'DEFUSED_COLLISION_EVENT'
    | 'FAILED_PROTECTIVE_STOP'
    | 'FALSE_NEGATIVE'
    | 'FALSE_POSITIVE'
    | 'INCORRECT_CLASSIFICATION'
    | 'INTENTIONAL_BUMPER_HIT'
    | 'INTENTIONAL_BUMPER_HIT_BEYOND_CONTROL_OF_EXOS'
    | 'NOT_A_POTENTIAL_COLLISION_EVENT'
    | 'NO_COLLISION_SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS'
    | 'OTHER'
    | 'POTENTIAL_COLLISION_EVENT'
    | 'PROTECTIVE_STOP_BUT_INCORRECT_OBSTACLE_CLASSIFICATION'
    | 'SUCCESFULLY_IGNORED_OBSTACLE'
    | 'SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS'
    | 'TRUE_NEGATIVE'
    | 'TRUE_POSITIVE';
