import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import NotificationObstacleDetectionQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionQuestion';
import {
    CHOICES_ENVIRONMENTAL_CONDITIONS,
    CHOICES_GROUND_TYPE,
    CHOICES_OBSTACLE_TYPE,
    NotificationObstacleDetectionQuestionLabelType,
    NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionMultipleChoiceQuestion implements NotificationObstacleDetectionQuestionnaireItemInterface {
    private question!: NotificationObstacleDetectionQuestion;

    constructor(
        id: string,
        labelType: NotificationObstacleDetectionQuestionLabelType,
    ) {
        this.question = new NotificationObstacleDetectionQuestion(id, labelType);
    }

    public getId(): string {
        return this.question.getId();
    }

    public getType(): NotificationObstacleDetectionQuestionnaireItemType {
        return 'MULTIPLE_CHOICE';
    }

    public getLabel(): string {
        return this.question.getLabel();
    }

    public getLabelType(): NotificationObstacleDetectionQuestionLabelType {
        return this.question.getLabelType();
    }

    public getChoices(): string[] {
        switch (this.question.getLabelType()) {
            case 'OBSTACLE_TYPE_PRESENT':
                return CHOICES_OBSTACLE_TYPE;
            case 'GROUND_TYPE':
                return CHOICES_GROUND_TYPE;
            case 'ENVIRONMENTAL_CONDITIONS':
                return CHOICES_ENVIRONMENTAL_CONDITIONS;
        }

        throw new Error(`Could not create choices for label type: ${this.question.getLabelType()}`);
    }
}
