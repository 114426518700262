
















































import NotificationObstacleDetectionSwitch
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionSwitch';

import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Notification } from '@/core/interfaces/notification';
import {
    NotificationObstacleDetectionDerivedQuestionType,
    NotificationObstacleDetectionQuestionLabelType,
    NotificationObstacleDetectionQuestionnaireItemType, SHOULD_STOP_FOR_OBSTACLE_TYPES,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';
import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import NotificationObstacleDetectionBooleanQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionBooleanQuestion';
import NotificationObstacleDetectionMultipleChoiceQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionMultipleChoiceQuestion';
import NotificationObstacleDetectionLabel
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionLabel';
import NotificationObstacleDetectionDerivedQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionDerivedQuestion';
import { Component, Prop } from 'vue-property-decorator';
import NotificationObstacleDetectionQuestionnaireQuestion
    from '@/components/notification/NotificationObstacleDetectionQuestionnaireQuestion.vue';
import Modal from '@/components/Modal.vue';
import NotificationSnapshot from '@/components/notification/Snapshot/NotificationSnapshot.vue';
import NotificationAttachments from '@/components/notification/NotificationAttachments.vue';

export const isBooleanQuestion = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionBooleanQuestion => {
    return item.getType() === 'BOOLEAN';
};

export const isMultipleChoice = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionMultipleChoiceQuestion => {
    return item.getType() === 'MULTIPLE_CHOICE';
};

export const isLabel = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionLabel => {
    return item.getType() === 'LABEL';
};

export const isDerived = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionDerivedQuestion => {
    return item.getType() === 'DERIVED';
};

export const isSwitch = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionSwitch => {
    return item.getType() === 'SWITCH';
};

const isQuestion = (item: NotificationObstacleDetectionQuestionnaireItemInterface): boolean => {
    return isMultipleChoice(item) || isBooleanQuestion(item);
};

@Component({
    components: {
        NotificationAttachments,
        NotificationSnapshot,
        Modal,
        NotificationObstacleDetectionQuestionnaireQuestion,
    },
})
export default class NotificationObstacleDetectionQuestionnaire extends Vue {
    @Prop({ required: true })
    notification!: Notification;

    @Getter('questionnaire/currentItem')
    currentItem!: NotificationObstacleDetectionQuestionnaireItemInterface;

    @Getter('questionnaire/robotDataById')
    robotDataById!: (id: string) => any;

    @Getter('questionnaire/answerById')
    answerById!: (id: string) => string[] | boolean | undefined;

    remarks = '';

    get itemType(): NotificationObstacleDetectionQuestionnaireItemType {
        return this.currentItem.getType();
    }

    get labelType(): NotificationObstacleDetectionQuestionLabelType {
        if (isMultipleChoice(this.currentItem) || isBooleanQuestion(this.currentItem)) {
            return this.currentItem.getLabelType();
        }

        throw new Error('Current item is not a question');
    }

    get isQuestion(): boolean {
        return isQuestion(this.currentItem);
    }

    get isDone(): boolean {
        return this.$store.state.questionnaire.done;
    }

    get selectedAnswer(): string[] | string | boolean {
        return this.$store.getters['questionnaire/answerById'](this.currentItem.getId());
    }

    get lastQuestion(): string | null {
        return this.$store.getters['questionnaire/lastQuestionId'];
    }

    get previousQuestion(): string | null {
        if (this.isDone) {
            return this.lastQuestion;
        }

        return this.$store.getters['questionnaire/previousQuestionId'](this.currentItem.getId());
    }

    mounted(): void {
        this.remarks = this.$store.getters['questionnaire/originalRemarks']();
    }

    previous(): void {
        const id = this.previousQuestion;
        this.$store.commit('questionnaire/setCurrentId', id);
        this.$store.commit('questionnaire/setDone', false);
        this.$store.dispatch('questionnaire/removeLabelsAndQuestionsAfterId', id);
    }

    answer(value: boolean | string[] | string): void {
        this.$store.dispatch('questionnaire/removeLabelsAndQuestionsAfterId', this.currentItem.getId());

        this.$store.commit('questionnaire/setAnswer', {
            id: this.currentItem.getId(),
            value,
        });

        let nextItem = this.$store.getters['questionnaire/nextItemById'](this.currentItem.getId()) as NotificationObstacleDetectionQuestionnaireItemInterface | null;

        while (nextItem !== null) {
            if (isQuestion(nextItem)) {
                this.$store.commit('questionnaire/setCurrentId', nextItem.getId());
                return;
            }

            if (isSwitch(nextItem)) {
                const previousAnswer = this.$store.getters['questionnaire/previousAnswer'](nextItem.getId());

                if (previousAnswer) {
                    nextItem = nextItem.getTrue()[0];
                } else {
                    nextItem = nextItem.getFalse()[0];
                }

                continue;
            }

            if (isDerived(nextItem)) {
                this.$store.commit('questionnaire/setAnswer', {
                    id: nextItem.getId(),
                    value: this.calculateDerivedValue(nextItem.getDerivedType()),
                });
            }

            if (isLabel(nextItem)) {
                // handle label
                this.$store.commit('questionnaire/addLabel', nextItem.getLabel());

                if (nextItem.isExit()) {
                    this.$store.commit('questionnaire/setDone', true);

                    return;
                }
            }

            nextItem = this.$store.getters['questionnaire/nextItemById'](nextItem.getId()) as NotificationObstacleDetectionQuestionnaireItemInterface | null;
        }
    }

    calculateDerivedValue(derivedType: NotificationObstacleDetectionDerivedQuestionType): boolean {
        switch (derivedType) {
            case 'CORRECT_OBSTACLE_CLASSIFICATION':
                return this.answerById('OBSTACLE_TYPE_PRESENT') === this.robotDataById('"detected_obstacle_classification"');
            case 'DID_EXOS_MAKE_PROTECTIVE_STOP':
                return this.robotDataById('did_exos_make_protective_stop');
            case 'DID_THE_EXOS_DETECT_OBSTACLE':
                return this.robotDataById('did_the_exos_detect_obstacle');
            case 'IS_BUMPER_TRIGGERED':
                return this.robotDataById('is_bumper_triggered');
            case 'MUST_STOP_FOR_OBSTACLE':
                return SHOULD_STOP_FOR_OBSTACLE_TYPES.includes(String(this.answerById('OBSTACLE_TYPE_PRESENT')));
            default:
                throw new Error(`Unknown derived type: ${derivedType}`);
        }
    }

    async save(): Promise<void> {
        this.$store.commit('questionnaire/setAnswer', {
            id: 'remarks',
            value: this.remarks,
        });

        await this.$store.dispatch('questionnaire/save');
        this.$emit('close');
    }
}
