












import { Component } from 'vue-property-decorator';
import UsesNotificationDetails from '@/components/mixins/UsesNotificationDetails';
import NotificationObstacleDetectionQuestionnaire
    from '@/components/notification/NotificationObstacleDetectionQuestionnaire.vue';

@Component
export default class NotificationQuestionnaireButton extends UsesNotificationDetails {
    public get questionnaireRequested(): boolean {
        return !!this.notification.obstacle_detection_questionnaire_requested_at;
    }

    public get questionnaireCompleted(): boolean {
        return !!this.notification.obstacle_detection_questionnaire_completed_at;
    }

    public async showQuestionnaireModal(): Promise<void> {
        await this.$store.dispatch('questionnaire/fetch', this.notification);

        this.$modal.show(
            NotificationObstacleDetectionQuestionnaire,
            {
                notification: this.notification,
            },
            {
                adaptive: true,
                height: 'auto',
                width: '800px',
                clickToClose: false,
            },
            {
                closed: () => this.refresh(),
            },
        );
    }

    public async refresh(): Promise<void> {
        this.$store.dispatch('runs/syncNotification', this.notification.id);
    }
}
