































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    @Prop({ type: String })
    protected title!: string;

    @Prop({ type: String })
    protected saveBtnCaption!: string;

    @Prop({ type: Boolean, default: true })
    protected showFooter!: boolean;

    @Prop({ type: Boolean, default: true })
    protected showCancel!: boolean;

    @Prop({ })
    protected classes!: string | Record<string, string>;

    @Prop({ })
    protected bodyClass!: string | Record<string, string>;

    get saveBtnCaptionOrFallback() {
        return this.saveBtnCaption ? this.saveBtnCaption : this.$t('general.save');
    }
}
