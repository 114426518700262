import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import {
    NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionSwitch implements NotificationObstacleDetectionQuestionnaireItemInterface {
    constructor(
        private readonly id: string,
        private readonly yes: NotificationObstacleDetectionQuestionnaireItemInterface[],
        private readonly no: NotificationObstacleDetectionQuestionnaireItemInterface[],
    ) {
    }

    public getId(): string {
        return this.id;
    }

    public getTrue(): NotificationObstacleDetectionQuestionnaireItemInterface[] {
        return this.yes;
    }

    public getFalse(): NotificationObstacleDetectionQuestionnaireItemInterface[] {
        return this.no;
    }

    public getType(): NotificationObstacleDetectionQuestionnaireItemType {
        return 'SWITCH';
    }

    public getLabel(): string {
        return 'Switch yes/no';
    }
}
