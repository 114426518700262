import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { QuestionnaireState } from '@/store/modules/questionnaire/questionnaireState';
import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import NotificationObstacleDetectionSwitch
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionSwitch';

export const isSwitch = (item: NotificationObstacleDetectionQuestionnaireItemInterface): item is NotificationObstacleDetectionSwitch => {
    return item.getType() === 'SWITCH';
};

const flattenItems = (items: NotificationObstacleDetectionQuestionnaireItemInterface[]): NotificationObstacleDetectionQuestionnaireItemInterface[] =>
    items.reduce((acc: NotificationObstacleDetectionQuestionnaireItemInterface[], item) => {
        if (!isSwitch(item)) {
            return [...acc, item];
        }

        return [...acc, item, ...flattenItems(item.getFalse()), ...flattenItems(item.getTrue())];
    }, []);

const getters: GetterTree<QuestionnaireState, RootState> = {
    currentItem: (state, getters) => {
        return getters.itemsFlattened.find((item: NotificationObstacleDetectionQuestionnaireItemInterface) => item.getId() === state.currentId);
    },

    previousAnswer: (state, getters) => (id: string) => {
        const idx = getters.itemsFlattened.findIndex((item: NotificationObstacleDetectionQuestionnaireItemInterface) => item.getId() === id);
        const previousId = getters.itemsFlattened[idx - 1].getId();

        return state.answers.find(answer => answer.id === previousId)?.value;
    },

    originalRemarks: (state, getters) => (id: string): string | undefined => {
        return state.originalAnswers.find(answer => answer.id === 'remarks')?.value as string | undefined;
    },

    answerById: (state, getters) => (id: string): string | string[] | boolean | undefined => {
        return state.answers.find(answer => answer.id === id)?.value || state.originalAnswers.find(answer => answer.id === id)?.value;
    },

    previousQuestionId: (state, getters) => (id: string) => {
        const itemIds = getters.itemsFlattened.map((item: NotificationObstacleDetectionQuestionnaireItemInterface) => item.getId());
        const answeredIds = state.answers.map(answer => answer.id);
        const idx = itemIds.findIndex((answerId: string) => answerId === id);

        if (idx < 1) {
            return null;
        }

        return itemIds.slice(0, idx)
            .reverse()
            .filter((id: string) => answeredIds.includes(id))
            .find((id: string) => ['BOOLEAN', 'MULTIPLE_CHOICE'].includes(getters.itemById(id)?.getType())) || null;
    },

    lastQuestionId: (state, getters) => {
        const itemIds = getters.itemsFlattened.map((item: NotificationObstacleDetectionQuestionnaireItemInterface) => item.getId());
        const answeredIds = state.answers.map(answer => answer.id);

        return itemIds
            .reverse()
            .filter((id: string) => answeredIds.includes(id))
            .find((id: string) => ['BOOLEAN', 'MULTIPLE_CHOICE'].includes(getters.itemById(id)?.getType())) || null;
    },

    isDone: (state) => () => {
        return state.done;
    },

    isAnswered: (state) => (id: string) => {
        return state.answers.map(answer => answer.id).includes(id);
    },

    isAnsweredTrue: (state) => (id: string) => {
        return state.answers.find(answer => answer.id === id)?.value === true;
    },

    isAnsweredFalse: (state) => (id: string) => {
        return state.answers.find(answer => answer.id === id)?.value === false;
    },

    itemsFlattened: (state): NotificationObstacleDetectionQuestionnaireItemInterface[] => flattenItems(state.items),

    nextItemById: (state, getters) => (id: string): NotificationObstacleDetectionQuestionnaireItemInterface | null => {
        const currentIndex = getters.itemsFlattened
            .map((item: NotificationObstacleDetectionQuestionnaireItemInterface) => String(item.getId()))
            .findIndex((itemId: string) => itemId === id);

        return getters.itemsFlattened[currentIndex + 1] || null;
    },

    itemById: (state, getters) => (id: string): NotificationObstacleDetectionQuestionnaireItemInterface | null => {
        return getters.itemsFlattened.find((item: NotificationObstacleDetectionQuestionnaireItemInterface) => item.getId() === id) || null;
    },

    robotDataById: (state) => (id: string): any => {
        return state.robotData.find(item => item.id === id)?.value;
    },
};

export default getters;
