import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import {
    NotificationObstacleDetectionDerivedQuestionType,
    NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionDerivedQuestion implements NotificationObstacleDetectionQuestionnaireItemInterface {
    constructor(
        private readonly id: string,
        private readonly derivedType: NotificationObstacleDetectionDerivedQuestionType,
    ) {

    }

    public getId(): string {
        return this.id;
    }

    public getType(): NotificationObstacleDetectionQuestionnaireItemType {
        return 'DERIVED';
    }

    public getLabel(): string {
        return this.derivedType;
    }

    public getDerivedType(): NotificationObstacleDetectionDerivedQuestionType {
        return this.derivedType;
    }
}
