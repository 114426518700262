import { QuestionnaireState } from './questionnaireState';
import items from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionFlowDatabase';

const state: QuestionnaireState = {
    notification: null,
    items,
    currentId: items[0].getId(),
    done: false,
    originalAnswers: [],
    answers: [],
    labels: [],
    robotData: [],
};

export default state;
