import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import NotificationObstacleDetectionQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionQuestion';
import {
    NotificationObstacleDetectionQuestionLabelType, NotificationObstacleDetectionQuestionnaireItemType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

export default class NotificationObstacleDetectionBooleanQuestion implements NotificationObstacleDetectionQuestionnaireItemInterface {
    private question!: NotificationObstacleDetectionQuestion;

    constructor(id: string, labelType: NotificationObstacleDetectionQuestionLabelType) {
        this.question = new NotificationObstacleDetectionQuestion(id, labelType);
    }

    public getId(): string {
        return this.question.getId();
    }

    public getType(): NotificationObstacleDetectionQuestionnaireItemType {
        return 'BOOLEAN';
    }

    public getLabel(): string {
        return this.question.getLabel();
    }

    public getLabelType(): NotificationObstacleDetectionQuestionLabelType {
        return this.question.getLabelType();
    }
}
