import NotificationObstacleDetectionBooleanQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionBooleanQuestion';
import NotificationObstacleDetectionSwitch
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionSwitch';
import NotificationObstacleDetectionLabel
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionLabel';
import NotificationObstacleDetectionMultipleChoiceQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionMultipleChoiceQuestion';
import NotificationObstacleDetectionDerivedQuestion
    from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionDerivedQuestion';
import NotificationObstacleDetectionQuestionnaireItemInterface
    from '@/core/obstacleDetectionQuestionnaire/interfaces/NotificationObstacleDetectionQuestionnaireItemInterface';
import {
    NotificationObstacleDetectionDerivedQuestionType,
    NotificationObstacleDetectionLabelType,
    NotificationObstacleDetectionQuestionLabelType,
} from '@/core/obstacleDetectionQuestionnaire/NotificationObstacleDetectionTypes';

let idx = 0;

const questionId = (type: NotificationObstacleDetectionQuestionLabelType) => `question_${type}_${idx++}`;
const switchId = (type: NotificationObstacleDetectionLabelType | NotificationObstacleDetectionDerivedQuestionType | NotificationObstacleDetectionQuestionLabelType) => `switch_${type}_${idx++}`;
const labelId = (labelType: NotificationObstacleDetectionLabelType) => `label_${labelType}_${idx++}`;
const derivedId = (type: NotificationObstacleDetectionDerivedQuestionType) => `derived_${type}_${idx++}`;

const items: NotificationObstacleDetectionQuestionnaireItemInterface[] = [
    new NotificationObstacleDetectionBooleanQuestion(questionId('IS_OBSTACLE_PRESENT'), 'IS_OBSTACLE_PRESENT'),
    new NotificationObstacleDetectionSwitch(switchId('IS_OBSTACLE_PRESENT'), [
        // IS_OBSTACLE_PRESENT: yes
        new NotificationObstacleDetectionLabel(labelId('POTENTIAL_COLLISION_EVENT'), 'POTENTIAL_COLLISION_EVENT'),
        new NotificationObstacleDetectionMultipleChoiceQuestion(questionId('ENVIRONMENTAL_CONDITIONS'), 'ENVIRONMENTAL_CONDITIONS'),
        new NotificationObstacleDetectionMultipleChoiceQuestion(questionId('GROUND_TYPE'), 'GROUND_TYPE'),
        new NotificationObstacleDetectionMultipleChoiceQuestion(questionId('OBSTACLE_TYPE_PRESENT'), 'OBSTACLE_TYPE_PRESENT'),
        new NotificationObstacleDetectionDerivedQuestion(derivedId('DID_THE_EXOS_DETECT_OBSTACLE'), 'DID_THE_EXOS_DETECT_OBSTACLE'),
        new NotificationObstacleDetectionSwitch(switchId('DID_THE_EXOS_DETECT_OBSTACLE'), [
            // DID_THE_EXOS_DETECT_OBSTACLE: yes
            new NotificationObstacleDetectionLabel(labelId('TRUE_POSITIVE'), 'TRUE_POSITIVE'),
            new NotificationObstacleDetectionDerivedQuestion(derivedId('CORRECT_OBSTACLE_CLASSIFICATION'), 'CORRECT_OBSTACLE_CLASSIFICATION'),
            new NotificationObstacleDetectionSwitch(switchId('CORRECT_OBSTACLE_CLASSIFICATION'), [
                // CORRECT_OBSTACLE_CLASSIFICATION: yes
                new NotificationObstacleDetectionLabel(labelId('CORRECT_CLASSIFICATION'), 'CORRECT_CLASSIFICATION'),
                new NotificationObstacleDetectionDerivedQuestion(derivedId('MUST_STOP_FOR_OBSTACLE'), 'MUST_STOP_FOR_OBSTACLE'),
                new NotificationObstacleDetectionSwitch(switchId('MUST_STOP_FOR_OBSTACLE'), [
                    // MUST_STOP_FOR_OBSTACLE: yes
                    new NotificationObstacleDetectionDerivedQuestion(derivedId('DID_EXOS_MAKE_PROTECTIVE_STOP'), 'DID_EXOS_MAKE_PROTECTIVE_STOP'),
                    new NotificationObstacleDetectionSwitch(switchId('DID_EXOS_MAKE_PROTECTIVE_STOP'), [
                        // DID_EXOS_MAKE_PROTECTIVE_STOP: yes
                        new NotificationObstacleDetectionLabel(labelId('SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS'), 'SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS'),
                        new NotificationObstacleDetectionDerivedQuestion(derivedId('IS_BUMPER_TRIGGERED'), 'IS_BUMPER_TRIGGERED'),
                        new NotificationObstacleDetectionSwitch(switchId('IS_BUMPER_TRIGGERED'), [
                            // IS_BUMPER_TRIGGERED: yes
                            new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), 'DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'),
                            new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), [
                                // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: yes
                                new NotificationObstacleDetectionLabel(labelId('INTENTIONAL_BUMPER_HIT_BEYOND_CONTROL_OF_EXOS'), 'INTENTIONAL_BUMPER_HIT_BEYOND_CONTROL_OF_EXOS', true),
                            ], [
                                // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: no
                                new NotificationObstacleDetectionLabel(labelId('BUMPER_HIT_BEYOND_CONTROL_OF_EXOS'), 'BUMPER_HIT_BEYOND_CONTROL_OF_EXOS', true),
                            ]),
                        ], [
                            new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), 'DID_OBSTACLE_COLLIDE_WITH_EXOS'),
                            new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), [
                                // DID_OBSTACLE_COLLIDE_WITH_EXOS: yes
                                new NotificationObstacleDetectionLabel(labelId('CRASH_EVENT_BEYOND_CONTROL_OF_EXOS'), 'CRASH_EVENT_BEYOND_CONTROL_OF_EXOS', true),
                            ], [
                                // DID_OBSTACLE_COLLIDE_WITH_EXOS: no
                                new NotificationObstacleDetectionLabel(labelId('NO_COLLISION_SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS'), 'NO_COLLISION_SUCCESFUL_PROTECTIVE_STOP_DUE_TO_ODS', true),
                            ]),
                        ]),
                    ], [
                        // DID_EXOS_MAKE_PROTECTIVE_STOP: no
                        new NotificationObstacleDetectionLabel(labelId('FAILED_PROTECTIVE_STOP'), 'FAILED_PROTECTIVE_STOP'),
                        new NotificationObstacleDetectionDerivedQuestion(derivedId('IS_BUMPER_TRIGGERED'), 'IS_BUMPER_TRIGGERED'),
                        new NotificationObstacleDetectionSwitch(switchId('IS_BUMPER_TRIGGERED'), [
                            // IS_BUMPER_TRIGGERED: yes
                            new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), 'DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'),
                            new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), [
                                // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: yes
                                new NotificationObstacleDetectionLabel(labelId('INTENTIONAL_BUMPER_HIT'), 'INTENTIONAL_BUMPER_HIT', true),
                            ], [
                                // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: no
                                new NotificationObstacleDetectionLabel(labelId('BUMPER_HIT_DUE_TO_FAILED_PROTECTIVE_STOP'), 'BUMPER_HIT_DUE_TO_FAILED_PROTECTIVE_STOP', true),
                            ]),
                        ], [
                            // IS_BUMPER_TRIGGERED: no
                            new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), 'DID_OBSTACLE_COLLIDE_WITH_EXOS'),
                            new NotificationObstacleDetectionSwitch(switchId('CRASH_EVENT_DUE_TO_FAILED_PROTECTIVE_STOP'), [
                                // DID_OBSTACLE_COLLIDE_WITH_EXOS: yes
                                new NotificationObstacleDetectionLabel(labelId('CRASH_EVENT_DUE_TO_FAILED_PROTECTIVE_STOP'), 'CRASH_EVENT_DUE_TO_FAILED_PROTECTIVE_STOP', true),
                            ], [
                                // DID_OBSTACLE_COLLIDE_WITH_EXOS: no
                                new NotificationObstacleDetectionLabel(labelId('DEFUSED_COLLISION_EVENT'), 'DEFUSED_COLLISION_EVENT', true),
                            ]),
                        ]),
                    ]),
                ], [
                    // DID_EXOS_MAKE_PROTECTIVE_STOP: no
                    new NotificationObstacleDetectionLabel(labelId('SUCCESFULLY_IGNORED_OBSTACLE'), 'SUCCESFULLY_IGNORED_OBSTACLE', true),
                ]),
            ], [
                // CORRECT_OBSTACLE_CLASSIFICATION: no
                new NotificationObstacleDetectionLabel(labelId('INCORRECT_CLASSIFICATION'), 'INCORRECT_CLASSIFICATION'),
                new NotificationObstacleDetectionDerivedQuestion(derivedId('DID_EXOS_MAKE_PROTECTIVE_STOP'), 'DID_EXOS_MAKE_PROTECTIVE_STOP'),
                new NotificationObstacleDetectionSwitch(switchId('DID_EXOS_MAKE_PROTECTIVE_STOP'), [
                    // DID_EXOS_MAKE_PROTECTIVE_STOP: yes
                    new NotificationObstacleDetectionLabel(labelId('PROTECTIVE_STOP_BUT_INCORRECT_OBSTACLE_CLASSIFICATION'), 'PROTECTIVE_STOP_BUT_INCORRECT_OBSTACLE_CLASSIFICATION', true),
                ], [
                    // DID_EXOS_MAKE_PROTECTIVE_STOP: no
                    new NotificationObstacleDetectionDerivedQuestion(derivedId('IS_BUMPER_TRIGGERED'), 'IS_BUMPER_TRIGGERED'),
                    new NotificationObstacleDetectionSwitch(switchId('IS_BUMPER_TRIGGERED'), [
                        // IS_BUMPER_TRIGGERED: yes
                        new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), 'DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'),
                        new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), [
                            // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: yes
                            new NotificationObstacleDetectionLabel(labelId('INTENTIONAL_BUMPER_HIT'), 'INTENTIONAL_BUMPER_HIT', true),
                        ], [
                            // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: no
                            new NotificationObstacleDetectionLabel(labelId('BUMPER_HIT_DUE_TO_FAILED_CLASSIFICATION'), 'BUMPER_HIT_DUE_TO_FAILED_CLASSIFICATION', true),
                        ]),
                    ], [
                        // IS_BUMPER_TRIGGERED: no
                        new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), 'DID_OBSTACLE_COLLIDE_WITH_EXOS'),
                        new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), [
                            // DID_OBSTACLE_COLLIDE_WITH_EXOS: yes
                            new NotificationObstacleDetectionLabel(labelId('CRASH_EVENT_DUE_TO_FAILED_CLASSIFICATION'), 'CRASH_EVENT_DUE_TO_FAILED_CLASSIFICATION', true),
                        ], [
                            // DID_OBSTACLE_COLLIDE_WITH_EXOS: no
                            new NotificationObstacleDetectionLabel(labelId('DEFUSED_COLLISION_EVENT'), 'DEFUSED_COLLISION_EVENT', true),
                        ]),
                    ]),
                ]),
            ]),
        ], [
            // DID_THE_EXOS_DETECT_OBSTACLE: no
            new NotificationObstacleDetectionLabel(labelId('FALSE_NEGATIVE'), 'FALSE_NEGATIVE'),
            new NotificationObstacleDetectionDerivedQuestion(derivedId('IS_BUMPER_TRIGGERED'), 'IS_BUMPER_TRIGGERED'),
            new NotificationObstacleDetectionSwitch(switchId('IS_BUMPER_TRIGGERED'), [
                // IS_BUMPER_TRIGGERED: yes
                new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), 'DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'),
                new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER'), [
                    // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: yes
                    new NotificationObstacleDetectionLabel(labelId('INTENTIONAL_BUMPER_HIT'), 'INTENTIONAL_BUMPER_HIT', true),
                ], [
                    // DID_OBSTACLE_INITIATE_CONTACT_WITH_BUMPER: no
                    new NotificationObstacleDetectionLabel(labelId('BUMPER_HIT_DUE_TO_FAILED_ODS'), 'BUMPER_HIT_DUE_TO_FAILED_ODS', true),
                ]),
            ], [
                // IS_BUMPER_TRIGGERED: no
                new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), 'DID_OBSTACLE_COLLIDE_WITH_EXOS'),
                new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), [
                    // DID_OBSTACLE_COLLIDE_WITH_EXOS: yes
                    new NotificationObstacleDetectionLabel(labelId('CRASH_EVENT_DUE_TO_FAILED_OBSTACLE_DETECTION'), 'CRASH_EVENT_DUE_TO_FAILED_OBSTACLE_DETECTION', true),
                ], [
                    // DID_OBSTACLE_COLLIDE_WITH_EXOS: no
                    new NotificationObstacleDetectionLabel(labelId('DEFUSED_COLLISION_EVENT'), 'DEFUSED_COLLISION_EVENT', true),
                ]),
            ]),
        ]),
    ], [
        // IS_OBSTACLE_PRESENT: no
        new NotificationObstacleDetectionLabel(labelId('NOT_A_POTENTIAL_COLLISION_EVENT'), 'NOT_A_POTENTIAL_COLLISION_EVENT'),
        new NotificationObstacleDetectionDerivedQuestion(derivedId('DID_THE_EXOS_DETECT_OBSTACLE'), 'DID_THE_EXOS_DETECT_OBSTACLE'),
        new NotificationObstacleDetectionSwitch(switchId('DID_THE_EXOS_DETECT_OBSTACLE'), [
            // DID_THE_EXOS_DETECT_OBSTACLE: yes
            new NotificationObstacleDetectionLabel(labelId('FALSE_POSITIVE'), 'FALSE_POSITIVE', true),
        ], [
            // DID_THE_EXOS_DETECT_OBSTACLE: no
            new NotificationObstacleDetectionLabel(labelId('TRUE_NEGATIVE'), 'TRUE_NEGATIVE'),
            new NotificationObstacleDetectionDerivedQuestion(derivedId('IS_BUMPER_TRIGGERED'), 'IS_BUMPER_TRIGGERED'),
            new NotificationObstacleDetectionSwitch(switchId('IS_BUMPER_TRIGGERED'), [
                // IS_BUMPER_TRIGGERED: yes
                new NotificationObstacleDetectionLabel(labelId('BUMPER_HIT_DUE_TO_OBSTACLE_OUT_OF_SIGHT'), 'BUMPER_HIT_DUE_TO_OBSTACLE_OUT_OF_SIGHT', true),
            ], [
                // IS_BUMPER_TRIGGERED: no
                new NotificationObstacleDetectionBooleanQuestion(questionId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), 'DID_OBSTACLE_COLLIDE_WITH_EXOS'),
                new NotificationObstacleDetectionSwitch(switchId('DID_OBSTACLE_COLLIDE_WITH_EXOS'), [
                    // DID_OBSTACLE_COLLIDE_WITH_EXOS: yes
                    new NotificationObstacleDetectionLabel(labelId('CRASH_EVENT_DUE_TO_OBSTACLE_OUT_OF_SIGHT'), 'CRASH_EVENT_DUE_TO_OBSTACLE_OUT_OF_SIGHT', true),
                ], [
                    // DID_OBSTACLE_COLLIDE_WITH_EXOS: no
                    new NotificationObstacleDetectionLabel(labelId('OTHER'), 'OTHER', true),
                ]),
            ]),
        ]),
    ]),
];

export default items;
